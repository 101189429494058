<template>
    <div>
        <div style=" background:white;line-height:30px;height:40"> 
            <div style=" display:flex;width:1500px;padding-left:3px ">
                <el-popover placement="top-start"  title="说明" width="200" trigger="hover"
                    content="状态说明：已确认--表示采购已确认,供应商可以报价; 审核：表示管理层对供应商报价已决策选中，此时单据已关闭">
                    <span slot="reference"><i class="iconfont icon-tishi" style="color:red;font-weight:700;font-size:24px"></i></span>
                </el-popover>
 
                <div style="margin-right:10px;font-size:12px;margin-left:5px;margin-top:3px;">状态</div>
                <el-select v-model="stfgSet"   size="small" @change="stfgChange" style="margin-top:3px;width:120px ">
                    <el-option label="所有状态" value="ALL"></el-option>
                    <el-option label="未确认" value="NO"></el-option>
                    <el-option label="已确认" value="OK"></el-option>
                    <el-option label="未审核" value="N"></el-option>
                    <el-option label="已审核" value="Y"></el-option>
                    <!-- <el-option label="已作废" value="X"></el-option> -->
                </el-select>
                <div style="line-height:30px;margin-top:3px" >
                    <span style=" margin-left:20px;font-size:12px;margin-right:10px;">字段查询</span>
                    <el-select v-model="selparam" size="small" placeholder="请选择列名" clearable  style="width:150px; " >
                        <span v-for="item in reqCol" :key='item.key'>
                            <el-option :label="item.title" :value="item.key"></el-option>
                        </span>
                    </el-select>
                    <el-input :placeholder="input_hold" v-model="textParam" @keyup.enter.native="stfgChange" size="small" style="width:320px; height:30px; ">
                        <el-select v-model="oper" slot="prepend"   style="width:100px; " >
                            <span v-for="item in operArr" :key='item.IDSEQ'>
                                <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                            </span>
                        </el-select>
                        <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                    </el-input> 
                </div>
            </div>
        </div>  
        <div> 
        <el-table  :data="reqData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="subHeight" 
              :row-key="getRowKey"
              ref="refreq"
              highlight-current-row
 
              style="overflow:auto;margin-top:1px;"> 
            <el-table-column  v-for="(item,index ) in reqCol.filter(item=>item.thide=='N')" :key="index"
                :prop="item.key"
                :label="item.title"
                show-overflow-tooltip
                :align="item.align"                                  
                :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                :min-width="item.width">
                <template   slot-scope="scope" >
                    <span v-if="item.key=='STFG'" >
                        <i v-if="scope.row.STFG==='OK'" class="iconfont icon-yiqueren" style="font-size:28px;color:#409eff" ></i>
                        <i v-if="scope.row.STFG==='NO'" class="iconfont icon-weiqueren" style="font-size:28px;" ></i>
                        <i v-if="scope.row.STFG==='Y'" class="iconfont icon-yishenhe" style="font-size:28px;color:green"></i>
                        <i v-if="scope.row.STFG==='N'" class="iconfont icon-weishenhe2" style="font-size:28px;"></i>
                    </span>
                    <span v-else v-text="scope.row[item.key]" ></span>
                </template>
            </el-table-column> 
 
             <el-table-column
                fixed="right"
                label="状态操作"
                align="center"
                width="80">
                <template slot-scope="scope">
                    <el-button size="mini" v-if="scope.row.STFG=='Y'"  type="text" icon="iconfont icon-chazhao" @click="editRec(scope.row,'Y',scope.$index)" style="margin-right:5px;">查看</el-button>
                    <el-button size="mini"  v-if="scope.row.STFG!=='Y'" type="text" icon="iconfont icon-bianji" @click="editRec(scope.row,'N',scope.$index)" style="margin-right:5px;">编辑</el-button> 
  
                </template>
            </el-table-column>  
        </el-table>
        </div>
        <!-- 分页查询 -->
        <div style="display:flex; line-height:35px;align-items:center;border-top:1px solid #ccc;padding-left:10px;background:white" >              
            <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
            <span style="color:#606266;margin:7px 10px;font-size:12px;">每页</span>
            <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                    <el-option 
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
            </el-select>
            <span style="color:#606266;margin:7px 10px;font-size:12px;">跳至</span>
            <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
            <span style="color:#606266;margin:7px 5px;font-size:12px;">页</span>
            <el-button size="small" @click="goNext" type="primary" style="width:50px;margin-left:15px;height:30px;">GO</el-button>
 
        </div>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 新增、编辑表单 -->
        <el-dialog class="pub_dialog"  :show-close="false" :visible.sync="addWin" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{addFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <div style="height:88vh;overflow-y:auto;padding-bottom:100px;">
                <Form :model="prcRow" :label-width="110"  label-position="right" inline  :rules="ruleValidate">  
                    <span v-for="(item,index) in reqCol" :key="index" >
                        <FormItem   :label="item.title"  class="item-width" v-if="item.fhide==='N'" :prop="item.key"  >                  
                            <el-date-picker :disabled="saveFlag" type="date"  v-if="item.edcss==='date'" v-model="prcRow[item.key]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                            <el-input-number :disabled="saveFlag" :precision="item.dotnum"  v-else-if="item.edcss==='number'" v-model="prcRow[item.key]" style="width:100%" ></el-input-number>   
                            <el-input :disabled="saveFlag ||item.disabled==='Y'?true:false" type="text" v-else   v-model="prcRow[item.key]" ></el-input>
                        </FormItem>
                    </span>
                </Form>
                <div class="one-toolbar"><span class="spanRow">1、请选择报价供应商</span> </div>
                <el-table  :data="supData" 
                    border                            
                    size="small"
                    :height="200" 
                    :row-key="getSupKey"
                    ref="refsup"
                    highlight-current-row > 
                        <el-table-column  v-for="(item ) in supCol.filter(item=>item.thide=='N')" :key="item.key"
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                            :min-width="item.width">
                            <template slot-scope="scope">
                                <span v-text="scope.row[item.key]"></span>
                            </template>
                        </el-table-column> 
                </el-table>
                <div class="one-toolbar"><span class="spanRow">2、请选择询价商品</span> </div>
                <el-table  :data="matData" 
                    border                            
                    size="small"
                    :height="300" 
                    :row-key="getMatKey"
                    @row-click="rowClick"
                    ref="refmat"
                    highlight-current-row > 
                    <el-table-column  v-for="(item ,index) in matCol.filter(item=>item.thide=='N')" :key="index" 
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox v-if="item.key==='ISOPEN'" disabled false-label="N" true-label="Y" v-model="scope.row.ISOPEN" >&nbsp;</el-checkbox>
                            <span  v-else v-text="scope.row[item.key]"></span>
                        </template>
                    </el-table-column> 
                </el-table>
                <div class="one-toolbar"><span class="spanRow">3、供应商报价明细</span>
                    【
                        <i class="iconfont icon-xinzeng" style="color:white;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                        <el-link :disabled="addBool||saveFlag" @click="addPrc" :underline="false" style="color:white"><span style="font-size:13px;margin-right:15px;">新增报价</span></el-link>   
                    】
                </div>
                <el-table  :data="prcData" 
                    border                            
                    size="small"
                    :height="160" 
                    :row-key="getPrcKey"
                    ref="refprc"
                    highlight-current-row> 
                        <el-table-column  v-for="(item,index ) in prcCol.filter(item=>item.thide=='N')" :key="index"
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                            :min-width="item.width">
                            <template slot-scope="scope" >
                                <el-checkbox v-if="item.key==='ISOK'" disabled false-label="N" true-label="Y" v-model="scope.row.ISOK" >&nbsp;</el-checkbox>
                                <span v-else v-text="scope.row[item.key]"></span>
                            </template>
                        </el-table-column> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="130">
                        <template slot-scope="scope">
                            <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="prcEdit(scope.row )">编辑</el-button>
                            <el-button size="mini"   type="text" icon="iconfont icon-shanchu1" @click="prcDel(scope.row,scope.$index )">编辑</el-button>
                        </template>
                    </el-table-column>  
                </el-table>
            </div>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; " >               
                <span style="float:right">
                    <i class="web-font" style="font-size:14px;margin-right:20px;color:#ed4014;font-weight:600"> 提示：单据审核后不能再报价</i> 
                    <i v-if="prcRow['STFG']==='NO'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-weiqueren"> </i>
                    <i v-else-if="prcRow['STFG']==='OK'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-yiqueren"> </i>
                    <i v-else-if="prcRow['STFG']==='X'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-yizuofei"> </i>
                    <i v-else-if="prcRow['STFG']==='Y'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-yishenhe1"> </i>
                    <i v-else-if="prcRow['STFG']==='N'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-weishenhe3"> </i>
                    <el-button type="info" @click="addWin=false" size="small"  icon="iconfont icon-shanchu" style="margin-left:30px;">  取消</el-button>
 
                </span>
            </div>
        </el-dialog>
 
        <!-- 报价明细编辑 -->
        <el-dialog  :show-close="false" :visible.sync="prcWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">编辑记录</span>
            </div> 
            <Form :model="quoRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in prcCol" :key="index" >
                    <FormItem   :class=" 'item-width'" :prop="item.key"  v-if="item.fhide==='N'">                  
                        <template slot="label"><span v-if="item.key=='TAXPRC'" style="color:red;margin-right:10px">*</span>{{item.title}} </template>
                        <el-checkbox disabled border v-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="quoRow[item.key]" style="width:100%"></el-checkbox> 
                        <el-input-number controls-position="right" :precision="item.dotnum"  v-else-if="item.edcss==='number'" v-model="quoRow[item.key]" style="width:100%" ></el-input-number>   
                        <el-input disabled type="text" v-else-if="item.key==='OKRMK'" v-model="quoRow[item.key]"></el-input>
                        <el-select v-model="quoRow[item.key]" v-else-if="item.key==='PAYTERM'"  clearable  @change="elchange" style="width:100%">
                            <span v-for="(item,index ) in payArr" :key='index'>
                                <el-option :label="item.CNAME" :value="item.CODE"></el-option>
                            </span>
                        </el-select>
                        <el-input  type="text" v-else :disabled="item.disabled=='Y'"   v-model="quoRow[item.key]"></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="prcWin=false" style="margin-right:50px;">取消</el-button>
                    <el-button type="primary" size="small" icon="iconfont icon-baocun"  @click="prcOk" style="margin-right:10px;">确定</el-button>
                </span>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {getBaseData,getTabColOrData} from '../../api/user'; 
import {paramData,getDate} from '../../api/Select' 
 
export default {
    name:'request_quote',
    data () {
        return {
            quoRow:{},
            prcWin:false,
            supWin:false,
 
            prdWin:false,
            promptWin:false,
            dtlFlag:'create',
            prdRow:{},
            supFlag:'',
            addFlag:'create',
            supRow:{
                SUPNO:'' ,//供应商编码
                SUPNM:'', //供应商名称
                RMK:'', //备注
            },
            rowSup:{},
            delPrdArr:[],
            stfgSet:'ALL',
            delSupArr:[],
            prompt:'',
            input_hold:'请输入内容...',
            ruleValidate:{},
            selparam:'',
            textParam:'',
            prcRow:{},
            prdFlag:'create',
            goPage:'',
            supAdd:false,
            reqData:[],
            cfmBool:false, //审核 权限
            addBool:false, //新增 权限
            editBool:false, //编辑权限
            cancelBool:false, //作废权限
            loading:false,
            current:1,
            saveFlag:false,
            allList:[], //询价单集合
            supData:[],
            matData:[],
            addWin:false,
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_url:this.$store.state.queryUrl , //api请求路径   
 
            options: [ {
                value: 50,
                label: '50'
                }, {
                value: 100,
                label: '100'
            }],
            dataCount:0,
            rowSize:50,
            supCol:[],
            matCol:[],
            reqCol:[],
            operArr:[],
            prcCol:[],
            prcData:[],
            payArr:[],
            oper:'like',
            v_idseq: '',
            v_lstseq:'',
        }
    },
    components: {
 
    },
    computed: {
        tabWidth: function() {
            return (window.innerWidth - 140) + 'px';
        }, 
        subHeight: function() {
            return (window.innerHeight - 155) + 'px';
        }, 
    },
    mounted () {
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        });  
        paramData('PARENTNO','19672','V_SYS_BASE').then((res)=>{
            this.payArr =res.data.result
        })
         //用户权限表
        getBaseData(this.$store.state.nologinUrl, 'idseq',this.$cookies.get('idseq'),'frmidseq','36','v_sys_user_priv','').then(res=>{
            this.privArr=res.data.result
            if (this.privArr[0].ISCFM==='N'){ //审核
                this.cfmBool=true
            }if (this.privArr[0].ISADD==='N'||this.privArr[0].DEPTID==='19541'){ //新增 ,部门为供应商时不能新增
                this.addBool=true
            }if (this.privArr[0].ISUPD==='N'){ //编辑
                this.editBool=true
            }if (this.privArr[0].ISCANCEL==='N'){ //作废
                this.cancelBool=true
            }
        })   
 
    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
            this.getPrcList('') //询价单主数据    
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }
    },
    methods: {
        prcOk(){
            if (this.quoRow['TAXPRC']) {
                let vm =this
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/tableBodySave2?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_json:JSON.stringify(Object.assign(this.quoRow,{IDSEQ:this.v_idseq,LSTSEQ:this.v_lstseq})),p_table:'po_prc_matdtl' },
                }).then(res=>{      
                    if(res.data.code =='200')  {           
                        this.getQuoteList(this.v_idseq,this.v_lstseq)
                        this.prcWin=false
                    }else{
                        this.promptWin =true
                        this.prompt='idseq:'+this.v_idseq+',lstseq:'+this.v_lstseq +res.data.result
                    }        
                })
            }else{
                this.promptWin =true
                this.prompt='含税单价不能为空'
            }
        },
        prcEdit(row){
            this.prcWin=true
            this.quoRow=JSON.parse(JSON.stringify(row))
        },
        //报价明细 
        getQuoteList(idseq,lstseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_po_prc_matdtl','idseq',idseq,'lstseq',lstseq,'','','' ).then((res) => { 
                this.prcData =res.data 
            })
            this.$nextTick(() => { //有固定列时 查询后重新布局
                this.$refs.refprc.doLayout();
            }) 
        },
        //编辑记录
        editRec(row,val,index){
            if (val==='Y'){  //仅查看
                this.saveFlag=true
            }else{
                if (row.STFG==='Y'){
                    this.saveFlag=true
                }else{
                    this.saveFlag=false
                }   
            }
            this.addFlag='edit'
            this.prcRow =JSON.parse(JSON.stringify(row))
            //供应商数据
            this.getSupList(row.IDSEQ)
            //商品数据
            this.getMatList(row.IDSEQ)
            this.addWin=true      
        },
 
        getRowKey(row){
            return row.IDSEQ
        },
        getSupKey(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        getMatKey(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        getPrcKey(row){
            return row.IDSEQ+'-'+row.LSTSEQ+'-'+row.ITMSEQ
        },
        //删除
        prcDel(row,index){
            this.$confirm('你确定要删除此记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
               this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row['IDSEQ'],p_key2:'lstseq',p_value2:row['LSTSEQ'],p_key3:'itmseq',p_value3:row['ITMSEQ'], p_table:'PO_PRC_MATDTL'},
                }).then(()=>{
                    this.prcData.splice(index,1)
                })  
            }).catch(()=>{})            
        },
        addPrc(){
            if (this.matData.length>0 ){
                this.prcWin =true
                this.quoRow ={}
            }else{
                this.promptWin =true
                this.prompt ='请选择询价商品'
            }
        },
        // 行点击事件
        rowClick(row){
            //明细查询
            this.getQuoteList(row.IDSEQ,row.LSTSEQ)
            this.v_idseq=row.IDSEQ
            this.v_lstseq=row.LSTSEQ
        },
        //状态查询
        stfgChange(){
            let cond_=''
            if (this.selparam){
                if (this.oper==='like'){
                    cond_=cond_+' and '+this.selparam+' like \'%'+this.textParam+'%\''
                }else{
                    cond_=cond_+' and '+this.selparam+this.oper+'\''+this.textParam+'\''
                }
            }
            if (this.stfgSet!=='ALL'){
               cond_=cond_+' and  Stfg=\''+this.stfgSet+'\''
            }
            this.getPrcList(cond_)
        }, 
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.reqData = this.allList.slice(_start,_end);
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allList.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allList.length < this.rowSize){
                this.reqData = this.allList          
            }else{
                this.reqData =this.allList.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
            
        },
 
        //报价单 
        getPrcList(cond){
            let vm =this
            let v_cond =' and idseq in (select idseq from V_po_prc_sup where supID=\''+this.v_username+'\')'
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_po_prc','','','','','','',v_cond +cond ).then((res) => { 
                this.allList =res.data 
                this.initPage() 
                if (res.data.length>0) {
                    this.$nextTick(() => { //有固定列时 查询后重新布局
                        this.$refs.refreq.doLayout();
                    })
                }
            })
        },
        //报价供应商 
        getSupList(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_po_prc_sup','idseq',idseq,'','','','',' and supid=\''+this.v_username+'\'' ).then((res) => { 
                this.supData =res.data 
            })
        },
        //报价商品 
        getMatList(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_po_prc_mat','idseq',idseq,'','','','','' ).then((res) => { 
                this.matData =res.data 
                if (res.data.length>0){
                    this.getQuoteList(res.data[0].IDSEQ,res.data[0].LSTSEQ)
                    this.v_idseq=res.data[0].IDSEQ
                    this.v_lstseq=res.data[0].LSTSEQ
                    this.$refs['refmat'].setCurrentRow(res.data[0])
                }else{
                    this.prcData=[]
                }
            })
        },
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','36','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.reqCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE, 
                        fhide: res.data[k].FHIDE,                                               
                        disabled: res.data[k].DISABLED, 
                        edcss: res.data[k].EDCSS, 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','36','fty','HEAD2','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.supCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE, 
                        fhide: res.data[k].FHIDE,                                               
                        disabled: res.data[k].DISABLED, 
                        edcss: res.data[k].EDCSS, 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','36','fty','HEAD3','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.matCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE, 
                        fhide: res.data[k].FHIDE,                                               
                        disabled: res.data[k].DISABLED, 
                        edcss: res.data[k].EDCSS, 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','36','fty','HEAD4','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.prcCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE, 
                        fhide: res.data[k].FHIDE,                                               
                        disabled: res.data[k].DISABLED, 
                        edcss: res.data[k].EDCSS, 
                    })
                }
            })
        },
    }
}
</script>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 16px !important;
            color:rgb(27, 11, 11);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
 
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
</style>